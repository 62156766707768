import React from "react";
import "./Header.css";

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo">BoldCharter</div>
      <nav>
        <ul>
          <li>
            <a href="#demos">Demos</a>
          </li>
          <li>
            <a href="#contact">Contact us</a>
          </li>
          <li>
            <a href="#docs">Read docs</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

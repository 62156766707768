import React from "react";
import "./Features.css";

interface FeatureProps {
  icon: string;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
  <div className="feature">
    <div className="feature-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const Features: React.FC = () => {
  const features = [
    {
      icon: "💬",
      title: "Truly conversational",
      description:
        "Our agents can hold lengthy high-quality intent driven conversations",
    },
    {
      icon: "🔀",
      title: "Choice of Models",
      description:
        "Experiment between multiple best-in-class proprietary and open source models",
    },
    {
      icon: "🌐",
      title: "Multilingual",
      description:
        "Our agent is fluent in multiple languages, including Hinglish",
    },
    // Add more features here
  ];

  return (
    <section className="features">
      <h2>Automate calls and tasks using AI agents built within minutes</h2>
      <p>Harness the power of truly conversational human-like voice</p>
      <div className="features-grid">
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </div>
    </section>
  );
};

export default Features;

import React from "react";
import "./Hero.css";

const Hero: React.FC = () => {
  return (
    <section className="hero">
      <h1>Create voice based AI agents</h1>
      <p>
        An end to end platform to build, deploy and monitor voice-based AI
        agents
      </p>
      <div className="cta-buttons">
        <button className="cta-call">
          Talk to Mr BoldCharter at 08047495254
        </button>
        <button className="cta-create">Create your first agent →</button>
      </div>
      <div className="phone-mockup">{/* Add phone mockup image here */}</div>
    </section>
  );
};

export default Hero;
